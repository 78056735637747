var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            {
              attrs: {
                permissions: ["EditExpertise", "EditExpertiseRestricted"],
              },
            },
            [_c("pga-edit-button", { on: { click: _vm.Edit } })],
            1
          ),
          _c(
            "pga-authorized-content",
            {
              attrs: {
                permissions: [
                  "SelectUsersExpertise",
                  "SelectUsersExpertiseRestricted",
                ],
              },
            },
            [
              _vm.IsHandleable
                ? _c("pga-edit-button", {
                    attrs: {
                      text: "Select Users",
                      icon: "mdi-account-multiple-plus",
                    },
                    on: { click: _vm.SelectUsers },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "pga-authorized-content",
            { attrs: { permission: "DeleteExpertise" } },
            [
              _vm.IsHandleable
                ? _c("pga-delete-button", {
                    on: { click: _vm.ShowDialogDelete },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.ExpertiseDetails } },
        [
          _c("pga-expertise-Details", {
            model: {
              value: _vm.ExpertiseDetails,
              callback: function ($$v) {
                _vm.ExpertiseDetails = $$v
              },
              expression: "ExpertiseDetails",
            },
          }),
          _c("pga-dialog", {
            attrs: {
              title: _vm.title,
              "icon-title": "mdi-delete-alert",
              message: _vm.message,
              color: "orange",
              "icon-button": "mdi-checkbox-marked-circle-outline",
            },
            on: { agree: _vm.Delete, reject: _vm.HideDialog },
            model: {
              value: _vm.showDialog,
              callback: function ($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }