var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12" },
    [
      _c("v-card-title", [
        _vm._v(" " + _vm._s(_vm.description) + " Expertise Details "),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("pga-number-field", {
                        attrs: { label: "Code", readonly: "" },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Description", readonly: "" },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-select", {
                        attrs: {
                          "item-value": "Id",
                          "item-text": "Name",
                          items: _vm.techAreas,
                          label: "Tech Areas",
                          multiple: "",
                          readonly: "",
                        },
                        model: {
                          value: _vm.techAreas,
                          callback: function ($$v) {
                            _vm.techAreas = $$v
                          },
                          expression: "techAreas",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-select", {
                        attrs: {
                          "item-value": "Id",
                          "item-text": "Description",
                          items: _vm.technologies,
                          label: "Technologies",
                          multiple: "",
                          readonly: "",
                        },
                        model: {
                          value: _vm.technologies,
                          callback: function ($$v) {
                            _vm.technologies = $$v
                          },
                          expression: "technologies",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Last Modify", readonly: "" },
                        model: {
                          value: _vm.modifiedDate,
                          callback: function ($$v) {
                            _vm.modifiedDate = $$v
                          },
                          expression: "modifiedDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Modified By", readonly: "" },
                        model: {
                          value: _vm.modifiedBy,
                          callback: function ($$v) {
                            _vm.modifiedBy = $$v
                          },
                          expression: "modifiedBy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("pga-data-table", {
                        attrs: {
                          title: "Users",
                          headers: _vm.headers,
                          data: _vm.users,
                          "col-props": ["Skill"],
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "column-Skill",
                            fn: function (props) {
                              return [
                                _c("pga-progress-linear", {
                                  attrs: {
                                    height: "20",
                                    value: (props.row.item.Skill * 100) / 3,
                                    color: _vm.color(props.row.item.Skill),
                                    stripped: true,
                                    content: [
                                      "Basic",
                                      "Intermediate",
                                      "Advanced",
                                    ],
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }